<template>
    <b-container id="mode">
        <b-jumbotron header="404" lead="ไม่พบหน้าที่คุณเรียก">
            <hr>
            <b-input-group class="mt-3">
                <b-form-input class="searchInput" v-model="search" placeholder="ค้นหา" @keypress.enter.prevent="searchNovel()"></b-form-input>
                <b-input-group-append>
                    <b-button type="submit" variant="primary" @click="searchNovel()">ค้นหา</b-button>
                </b-input-group-append>
            </b-input-group>
        </b-jumbotron>
    </b-container>
</template>

<script>
import router from './../../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            mode: 'GET_MODE'
        })
    },
    methods:{
        ...mapActions(['SET_KEYWORD', 'SET_SEARCH_NOVEL_WORD', 'SET_LOADING_STATUS', 'SET_MODE']),
        searchNovel() {
            this.SET_LOADING_STATUS(true)
            var queryParams = new URLSearchParams(window.location.search);
            this.SET_KEYWORD(this.search)
            this.SET_SEARCH_NOVEL_WORD(this.search)
            if (window.location.pathname.includes('/search')) {
                queryParams.set("search", this.search);
                history.replaceState(null, null, "?" + queryParams.toString());
            } else {
                router.push('/search')
            }
            this.search = ""
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            if(document.getElementById('mode').classList.contains('darkmode')){
                document.getElementById('mode').classList.remove('darkmode')
            }
        },
        darkmode() {
            if(!document.getElementById('mode').classList.contains('darkmode')){
                document.getElementById('mode').classList.add('darkmode')
            }
        }
    },
    mounted: function () {
        this.checkMode()
    }
};
</script>

<style scoped>
#mode{
    padding: 2% 2% 2% 2%;
}
.jumbotron {
    background: #fff;
}
.darkmode .jumbotron {
    background-color: #35363A;
    color: #FFF;
}
.darkmode h1{
    color: #fff
}
.darkmode hr{
    border-color: #fff;
}
</style>